import React, { Component } from 'react'
import './InstallPrompt.css'
import { shouldPrompt } from 'utils/installPrompt'

export default class InstallPrompt extends Component {
  render() {
    const { app } = this.props
    const name = app.name || 'Untitled'
    const icon = app.icon || 'default-app-icon.png'
    const iconURL = `https://proton-resources-production.imgix.net/${icon}?fit=crop&w=64&h=64&`

    if (!shouldPrompt(app)) {
      return null
    }

    return (
      <div className="install-prompt">
        <div className="install-prompt-header">
          <img
            alt={name}
            src={iconURL}
            className="install-prompt-icon"
            width={32}
            height={32}
          />
          <h4 className="install-prompt-title">{name}</h4>
        </div>
        <span
          className="install-prompt-button"
          onClick={() => {
            // Reference: https://web.dev/learn/pwa/installation-prompt#see_it_in_action

            /** @type {import('beforeinstallprompt').BeforeInstallPromptEvent} */
            // eslint-disable-next-line react/destructuring-assignment
            const deferredPrompt = this.props.deferredPrompt

            if (deferredPrompt) {
              deferredPrompt.prompt()
            }
          }}
        >
          Add to Home Screen
        </span>
      </div>
    )
  }
}
